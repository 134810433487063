code[class*="language-"],
pre[class*="language-"] {
  font-family: Inconsolata, monospace !important;
  font-size: .75rem !important;
  color: $grey-dark;
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: $info;
}

.token.regex,
.token.important,
.token.variable {
  color: $red;
}

.token.important,
.token.bold {
  font-weight: normal;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: green;
}
