@font-face {
  font-family: 'source-sans-pro';
  url:('assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'playfair display';
  url:('assets/fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inconsolata', monospace;
  url:('assets/fonts/Inconsolata/Inconsolata-VariableFont_wdth,wght.ttf') format('truetype');
  url:('assets/fonts/Inconsolata/Inconsolata-VariableFont_wdth,wght.woff') format('woff');
  url:('assets/fonts/Inconsolata/Inconsolata-VariableFont_wdth,wght.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
