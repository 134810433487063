// Set brand colors
$orange: #FF9E00;
$red: #FF1E00;
$grey-darker: #363636;

// Update Bulma's global variables
$family-primary: "source-sans-pro", sans-serif;
$family-secondary: "playfair display";
$family-code: "Inconsolata", monospace;
$primary: $orange;
$link: $red;

// Update some of Bulma's component variables
$navbar-item-img-max-height: 2.75rem;
$shadow:0 0.5em 1em -0.125em rgba(255,30,0,1),0 0px 0 1px rgba(255,30,0,0.2);
$footer-padding: 3rem;

// Background Codeblock
$pre-background: none;
$pre-padding: unset;

